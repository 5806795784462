import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
const AUTH_TOKEN = 'apollo-token'
const dataState = createPersistedState({
  key: 'vuex',
    reducer (val) {
      if(!sessionStorage.getItem(AUTH_TOKEN)) { // val.user.token (your user token for example)
        return {}
      }
      return val
    },
  storage: window.sessionStorage,
})
export default new Vuex.Store({
  plugins: [dataState],
  state: {
    filtre:"",
    changed: false,
    alert:false,
    parametres: [],
    alerts:[],
    fields:["0"],
    isEditing: false,
    auth: [], 
    document:{},
    me: {}, 
    today:null,
    options: [],   
    structures: [], 
    planning:[]
  },
  mutations: {
    set_today(state, value) {      
      state.today = value;
    },
    set_options(state, value) {
      state.options = value;
    },
    set_planning(state, value) {
      state.planning = value;
    },
    set_structures(state, value) {
      state.structures = value;
    },
    set_fields(state, value) {
      state.fields = value;
    },
    set_document(state, value) {
      state.document = value;
    },
    set_editing(state, value) {
      state.isEditing = value;
    },
    set_filtre(state, value) {
      state.filtre = value;
    },

    set_alerts(state, value) {
      state.alerts = value;
    },

    set_changed(state, value){
      state.changed = value;
    },
    set_alert(state, value){
      state.alert = value;
    },
    set_parametres(state, parametres) {
      state.parametres = parametres;
    },
    ActualUser(state, user) {
      if (user) {
       let a = user.autorisations ? user.autorisations.split(",") : []
       let b=""
    
       if (user.profile)
       b = user.profile.autorisations ? user.profile.autorisations.split(",") : []
       state.auth = a.concat(b)
      //state.auth = user.autorisations ? user.autorisations.split(",") : []
      state.me = user;
      state.isadmin = user.profile_id == 1 ? true : false
    }
    },
  },
  actions: {
    ActualUser(context, user) {
      context.commit("ActualUser", user);
    },
    LoadOptions(context,data) {
      context.commit("set_options", data.options);         
      context.commit("set_alerts", data.alerts_list);        
      context.commit("set_today", data.getdate[0].today.slice(0, 10));  
      context.commit("set_structures", data.structures); 
      context.commit("set_planning", data.actual_planing);     
      
    },   
    Editing(context, value) {
      context.commit("set_editing", value);
    },
    Document(context, value) {
      context.commit("set_document", value);
    },
    Fields(context, value) {
      context.commit("set_fields", value);
    },
    Filtre(context, value) {
      context.commit("set_filtre", value);
    },

    Changed(context, value) {
      context.commit("set_changed", value);
    },
    alert(context, value) {
      context.commit("set_alert", value);
    },
  },
  modules: {
  }
})
