import Vue from 'vue'
import Vuex from "vuex";
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { createProvider } from './vue-apollo'
import "regenerator-runtime/runtime";
import IdleVue from "idle-vue";
import { readsession,requette,maj,calc_failure } from "./utils/functions";
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";

Vue.config.productionTip = false
Vue.prototype.$readsession = readsession();
Vue.prototype.$requette = requette;
Vue.prototype.$calc_failure = calc_failure;
Vue.prototype.$maj = maj;

const eventsHub = new Vue({
  router,
  store,
  vuetify,
  Vuex,
  apolloProvider: createProvider(),
  render: h => h(App),

}).$mount('#app')

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 600000, // 10 minutes
  startAtIdle: false,
},
);

Vue.use(VueGoogleMaps, {
  load: {
         key: "AIzaSyARYpUgjYWTCDY_jqIb-so1dxUo3NXFhDI",
          libraries: 'places,drawing',
        },
},
);
Vue.component("GmapCluster", GmapCluster);

