export function numberWithSpace(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
  }
export function readsession() {
    let storage = {};

      // Retrieve all keys and values from session storage
      for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        var value
        if (isJsonString(sessionStorage.getItem(key)))
         value = JSON.parse(sessionStorage.getItem(key));
        else value = sessionStorage.getItem(key)
        storage[key] = value;
      }
    return storage;
}
function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}



export async function requette(query, v,f="no-cache") {
    let r;

    await this.$apollo
      .query({
        query: query,
        variables: v,
        fetchPolicy: f,
      })
      .then((data) => {
        r = { ok: true, data: data.data };
      })
      .catch((error) => {
        r = { ok: false, error: error };
      });
    return r;
}
export async function maj(query, v, refresh) {
    let r;
    await this.$apollo
      .mutate({
        mutation: query,
        variables: v,
      })
      .then((data) => {
        if (refresh) {
          this.$store.dispatch("Changed", true);
          this.$emit("change");
        }
        r = { ok: true, data: data.data };
      })
      .catch((error) => {
        r = { ok: false, error: error };
      });
    return r;
}

export function formatAsCurrency(value, dec) {
    dec = dec || 0;
    if (value === null) {
      return 0;
    }
    if (value === "undefined") {
      return 0;
    }
    return value
      ? value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")
      : 0;
  }
export function sumTotal(list,key) {
    // sum data in give key (property)
    return list
      ? list.reduce((a, b) => a + (b[key] || 0), 0)
      : 0;
  }
  export function debounce (fn, delay) {
    var timeoutID = null
    return function () {
      clearTimeout(timeoutID)
      var args = arguments
      var that = this
      timeoutID = setTimeout(function () {
        fn.apply(that, args)
      }, delay)
    }
  }
// export function datefr(date_o) {
//     let o_date
//     if (!date_o) o_date = "";
//     else o_date = date_o;
//     return (
//       o_date.substr(8, 2) +
//       "-" +
//       o_date.substr(5, 2) +
//       "-" +
//       o_date.substr(0, 4)
//     );
//   }
  export function datefr(date, time = false,m="long") {
    let t = "";
    let option = {
      year: "numeric",
      month: m,
      day: "numeric",
    };
    if (time)
      option = {
        year: "numeric",
        month: m,
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
    if (date) {
      var result = new Date(date);
      t = result.toLocaleDateString("fr-FR", option);
    }
    return t;
  }
  export function dateam(date, time = false,m="long") {
    let t = "";
    let option = {
      year: "numeric",
      month: m,
      day: "numeric",
    };
    if (time)
      option = {
        year: "numeric",
        month: m,
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
    if (date) {
      var result = new Date(date);
      t = result.toLocaleDateString("en-US", option);
    }
    return t;
  }
  export const autorisations =[
    {
        "code": "01001",
        "autorisation": "Consulter Dashboard",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "01002",
        "autorisation": "Consulter Operateur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02002",
        "autorisation": "Editer Operateur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "03002",
        "autorisation": "Valider Operateur",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "01003",
        "autorisation": "Consulter Transactions",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "02003",
        "autorisation": "Editer Transactions",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "03003",
        "autorisation": "Executer Transactions",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "04003",
        "autorisation": "Valider Transactions",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "05003",
        "autorisation": "Annuler Transactions",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
        "code": "05003",
        "autorisation": "Consulter Toutes Transactions",
        "module": "1",
        "__typename": "Autorisation"
    },
    {
      "code": "01004",
      "autorisation": "Consulter les Intervenants",
      "module": "1",
      "__typename": "Autorisation"
    },
    {
      "code": "01005",
      "autorisation": "Consulter Credits",
      "module": "1",
      "__typename": "Autorisation"
  },
  {
      "code": "02005",
      "autorisation": "Editer Credits",
      "module": "1",
      "__typename": "Autorisation"
  },
  
  {
      "code": "04005",
      "autorisation": "Valider Credits",
      "module": "1",
      "__typename": "Autorisation"
  },
  {
      "code": "05005",
      "autorisation": "Annuler Credits",
      "module": "1",
      "__typename": "Autorisation"
  },
  {
    "code": "01006",
    "autorisation": "Consulter Echéances",
    "module": "1",
    "__typename": "Autorisation"
},
{
    "code": "02006",
    "autorisation": "Reporter Echéances",
    "module": "1",
    "__typename": "Autorisation"
},

{
    "code": "04006",
    "autorisation": "Exécuter Echéances",
    "module": "1",
    "__typename": "Autorisation"
},
{
    "code": "05006",
    "autorisation": "Annuler Echéances",
    "module": "1",
    "__typename": "Autorisation"
},
    {
      "code": "01010",
      "autorisation": "Gerer les Users",
      "module": "1",
      "__typename": "Autorisation"
    },
];

