import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', component: () => import('../views/Login.vue'),
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/',
    component: () => import('../views/MainLayout.vue'),
    children: [
      { path: '/dashboard',name:"dashboard", component: () => import('../views/Dashboard.vue') },
      { path: '/transactions',name:"transactions", component: () => import('../views/ListeTrans.vue') ,  props: { transtype: 1 }},
      { path: '/oprs',name:"oprs", component: () => import('../views/ListeTiers.vue')  },  
      { path: '/credits',name:"credits", component: () => import('../views/ListeCredits.vue')  }, 
      { path: '/echeances',name:"echeances", component: () => import('../views/ListeEcheances.vue')  },
      { path: '/agents',name:"agents", component: () => import('../views/ListeUsers.vue') , },
      { path: '/users', component: () => import('../views/Users.vue') },
      { path: '*', component: () => import('../components/NotFound.vue') },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
